import React from 'react';
import moment from 'moment';
import { TextCollapse } from '@clippingcacd/core';
import { AiOutlineEye } from 'react-icons/ai';
import { BiComment } from 'react-icons/bi';

import * as S from './styles';

const resourceString = {
  questions: 'questão',
};

export default function ForumTopicCard({ topic }) {
  const createdAt = moment(topic.createdAt);
  return (
    <S.Root target='_blank' href={`https://forum.clippingcacd.com.br/t/${topic.slug}`}>
      <S.CardHeader>
        <span id='topic-text'>
          Nova resposta no tópico{' '}"<b>{topic.text}</b>" no Fórum. <b style={{'color': '#FC5836'}}>&nbsp;#forum #{topic.category?.toLowerCase()}</b>{' '}
        </span>{' '}
        {' '}
        <span style={{'marginLeft': 'auto'}} >{topic.posters.map((poster) => <img style={{'borderRadius': '50%'}} src={poster}/>)}</span>
      </S.CardHeader>
      <S.Footer>
        <S.ActionsContainer>
          <div>
            <button>
              <AiOutlineEye />
              {topic.views}
            </button>

            <button>
              <BiComment />
              {topic.posts_count}
            </button>
          </div>

          <button>Comentar</button>
        </S.ActionsContainer>
        <S.CreateDate>{createdAt.fromNow()}</S.CreateDate>
      </S.Footer>
    </S.Root>
  );
}
