import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "material-ui/styles";
import styled from "styled-components";
import Dialog from "material-ui/Dialog";
import DialogContent from "material-ui/Dialog/DialogContent";
import DefaultLoading from "../../components/Loading";
import ResponsiveCourse from "./ResponsiveCourse.js";
import DesktopCourse from "./DesktopCourse.js";
import PaymentModal from "../Payments/Modais/PaymentModal.js";
import PaymentStatusModal from "../Payments/Modais/PaymentStatusModal";
import SnackBar from "../../components/SnackBar";

import { classInfoFetchRequest } from "../Cursos/actions";
import {
  userPaymentsFetchRequest,
  setChooseCardOpen,
  openFinancialResponsible,
  setCreditPayModalOpen,
  setChooseInstallmentsOpen,
  setPaymentStatusModalOpen,
  setLastPayed,
} from "../Payments/actions";
import { openConfirmExit, closeRegister } from "../Checkout/actions";

const styles = {
  root: {
    scrollbarWidth: "15px",
    position: "relative",
    padding: 0,
    paddingTop: 0,
    display: "block",
    width: "100vw",
    height: "fit-content",
    "&:first-of-type": {
      paddingTop: 0,
    },
  },
};

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
`;

function Curso({ classes, params }) {
  const dispatch = useDispatch();

  const loading = useSelector(
    (state) => state.courseGroup.loadCourseData.loading
  );

  const { error } = useSelector((state) => state.courseGroup.classInfo);

  const [scrolled, setScrolled] = useState(false);

  const responsive = useSelector((state) => state.plataform.responsive);

  const debits = Object.values(
    useSelector((state) => state.paymentGroup.debits.data.enabled)
  );
  const currentDebit = useSelector((state) => state.paymentGroup.currentDebit);
  const lastPayed = useSelector((state) => state.paymentGroup.lastPayed);

  const openPaymentModal = useSelector(
    (state) => state.paymentGroup.paymentModal.open
  );

  const profile = useSelector((state) => state.userData.data.id);
  const pacotes_modulo = useSelector(
    (state) => state.courseGroup.currentModule.data.pacotes_modulo
  );
  const pacotes_curso = useSelector(
    (state) => state.courseGroup.currentCourse.data.pacotes_curso
  );

  useEffect(() => {
    try {
      const { _courseID, _classID, _moduleID } = params;
      if (
        pacotes_modulo !== undefined &&
        pacotes_curso !== undefined &&
        lastPayed.length
      ) {
        if (
          pacotes_modulo.find((pacote) => lastPayed.includes(pacote.id)) ||
          pacotes_curso.find((pacote) => lastPayed.includes(pacote.id))
        ) {
          dispatch(
            classInfoFetchRequest({
              courseID: parseInt(_courseID),
              moduleID: parseInt(_moduleID),
              classID: parseInt(_classID),
              profile,
            })
          );
          dispatch(setLastPayed([]));
        }
      }
    } catch (error) {}
  }, [JSON.stringify(lastPayed), pacotes_modulo, pacotes_curso]);

  useEffect(() => {
    const content = document.getElementById("content");
    if (content) {
      content.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    const { _courseID, _classID, _moduleID } = params;
    if (
      _courseID !== undefined &&
      _moduleID !== undefined &&
      _classID !== undefined
    ) {
      dispatch(
        classInfoFetchRequest({
          courseID: parseInt(_courseID),
          moduleID: parseInt(_moduleID),
          classID: parseInt(_classID),
          profile,
        })
      );
    }
  }, [JSON.stringify(params)]);

  useEffect(() => {
    dispatch(userPaymentsFetchRequest());
    dispatch(setChooseCardOpen(false));
    dispatch(setPaymentStatusModalOpen(false));
    dispatch(setChooseInstallmentsOpen(false));
    dispatch(setCreditPayModalOpen(false));
    dispatch(openConfirmExit(false));
    dispatch(openFinancialResponsible(false));
    dispatch(closeRegister());
  }, []);

  function onScrollChange() {
    const content = document.getElementById("content");
    if (content.scrollTop > 220) {
      setScrolled(true);
    } else if (content.scrollTop < 220) {
      setScrolled(false);
    }
  }

  return (
    <div>
      {error ? (
        "Erro ao carregar aula"
      ) : (
        <Dialog id="dialog" scroll={"paper"} open={true} fullScreen={true}>
          <DialogContent
            id="content"
            onScroll={() => onScrollChange()}
            classes={{ root: classes.root }}
            dividers="false"
          >
            {loading ? (
              <LoadingContainer>
                <DefaultLoading sidebar={true} />
              </LoadingContainer>
            ) : responsive ? (
              <ResponsiveCourse debits={debits} />
            ) : (
              <DesktopCourse
                debits={debits}
                scrolled={scrolled}
                setScrolled={setScrolled}
              />
            )}
          </DialogContent>
          {debits.length > 0 && (
            <PaymentModal
              open={openPaymentModal}
              price={Math.ceil(currentDebit.value)}
              imageUrl={debits[0].teacher}
              moduleNumber={debits[0].modulo.number}
              moduleID={debits[0].modulo.id}
              courseName={debits[0].curso.name}
              courseID={debits[0].curso.id}
              classNumber={debits[0].aula.number}
              className={debits[0].aula.name}
              pacoteID={currentDebit.id}
            />
          )}
          <PaymentStatusModal qpq={true} />
          <SnackBar />
        </Dialog>
      )}
    </div>
  );
}

export default withStyles(styles, { index: 1 })(Curso);
