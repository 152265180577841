import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "react-router-redux";

import styled from "styled-components";

import ResponsiveCourseHeader from "../../components/CourseHeader/responsive";

import Video from "./Video";
import CoursePresentation from "./CoursePresentation";

import LoadingModal from "../Payments/Modais/LoadingModal";
import DefaultLoading from "../../components/Loading";
import VideoButton from "./Video/VideoButton";

import ClassDate from "../../components/ClassDate";
import CurrentClassInfo from "../../components/CurrentClassInfo";
import ClassTabsResponsive from "./ClassTabsResponsive";
import PaymentBar from "./PaymentBar";

import { createDebitRequest } from "../Payments/actions";
import {
  redirectToCheckoutAction,
  classSelectorModalAction,
} from "../Cursos/actions";
import { setCartProducts } from "../Checkout/actions";

const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 180px;
  height: fit-content;
`;

const ClassLoadingContainer = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 50%;
  width: fit-content;
  height: fit-content;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 56px;
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 16px;
`;

function ResponsiveCourse({ size, debits }) {
  const dispatch = useDispatch();

  const loadingClass = useSelector(
    (state) => state.courseGroup.classInfo.loading
  );
  const loadingDebits = useSelector(
    (state) => state.paymentGroup.debits.loading
  );
  const loadingModules = useSelector(
    (state) => state.courseGroup.modules.loading
  );

  const currentClass = useSelector(
    (state) => state.courseGroup.currentClass.data
  );
  const currentDebit = useSelector((state) => state.paymentGroup.currentDebit);

  const info = useSelector((state) => state.courseGroup.classInfo.data);
  const {
    showLive,
    showVideo,
    time,
    qpq,
    type,
    classTempo,
    availableDate,
  } = info;
  const perfil = useSelector((state) => state.userData.data.id);
  const liveID = currentClass.webinar_id;

  useEffect(() => {
    function hideIntercom() {
      const intercom = document.getElementsByClassName(
        "intercom-lightweight-app-launcher"
      )[0];

      if (intercom) {
        intercom.setAttribute("style", "display: none");
      }
    }
    function cleanup(timeout) {
      clearTimeout(timeout);

      const intercom = document.getElementsByClassName(
        "intercom-lightweight-app-launcher"
      )[0];
      if (intercom) {
        intercom.setAttribute("style", "display: block");
      }
    }
    if (!debits.length) {
      let timeout = setTimeout(() => {
        hideIntercom();
      }, 1000);
      return () => cleanup(timeout);
    }
    return;
  }, []);


  const {
    id: modulo,
    number: moduleNumber,
    value,
    pacotes_modulo,
  } = useSelector((state) => state.courseGroup.currentModule.data);

  const { data: courseData, showPresentation } = useSelector(
    (state) => state.courseGroup.currentCourse
  );

  const { checkout_url, id: curso } = courseData;

  const body = {
    perfil,
    curso,
    modulo,
    aula: currentClass.id,
  };

  function redirectToCheckout() {
    dispatch(redirectToCheckoutAction(true));
    if (pacotes_modulo.length) {
      dispatch(
        setCartProducts({
          items: [{ id: pacotes_modulo[0].id }],
          price: value,
        })
      );
    }

    if (checkout_url !== undefined) {
      return dispatch(push(`/checkout/${checkout_url}`));
    } else {
      return dispatch(push(`/checkout`));
    }
  }

  function watchLiveClass(payload, showLive, liveID, qpq) {
    window.open(`https://zoom.us/j/${liveID}`, "_blank");
    if (!showLive && qpq) {
      dispatch(createDebitRequest(payload));
    }
  }

  function registerDebit(payload) {
    dispatch(createDebitRequest(payload));
  }

  return (
    <ResponsiveContainer>
      <ResponsiveCourseHeader />
      {info.showPaymentBar &&
        !loadingDebits &&
        debits.length > 0 &&
        !showPresentation && (
          <PaymentBar
            // imageUrl={debits[0].curso.teacher.photo}
            moduleNumber={debits[0].modulo.number}
            courseName={debits[0].curso.name}
            courseYear={debits[0].curso.ano}
            classNumber={debits[0].aula.number}
            className={debits[0].aula.name}
            category={debits[0].curso.categoria}
            pacoteID={currentDebit.id}
          />
        )}
      {loadingClass || loadingModules ? (
        <ClassLoadingContainer>
          <DefaultLoading sidebar={true} />
        </ClassLoadingContainer>
      ) : showPresentation ? (
        <CoursePresentation />
      ) : (
        <MainContainer>
          <Video responsive={true} info={info} debits={debits.length > 0} />
          <MainWrapper>
            <CurrentClassInfo
              onClick={() => dispatch(classSelectorModalAction({ open: true }))}
              currentClassName={currentClass.name}
              moduleNumber={moduleNumber}
            />
            {classTempo && type !== 'notEnrolled' && (
              <ClassDate
                date={availableDate}
                time={time}
                classTempo={classTempo}
                responsive={true}
              />
            )}
            {type === "notEnrolled" ? (
              <VideoButton
                presentation={showPresentation}
                text={"Matricule-se aqui"}
                action={() => redirectToCheckout()}
              />
            ) : type === "live" ? (
              <VideoButton
                presentation={showPresentation}
                text={"Entrar na sala"}
                action={() => watchLiveClass(body, showLive, liveID, qpq)}
              />
            ) : type === "hasToInscribe" ? (
              <VideoButton
                presentation={showPresentation}
                text={`Inscrever para aula ao vivo`}
                action={() => registerDebit(body)}
              />
            ) : (
              type === "available" &&
              !showVideo && (
                <VideoButton
                  presentation={showPresentation}
                  text={"Liberar gravação"}
                  action={() => registerDebit(body)}
                />
              )
            )}
            <ClassTabsResponsive
              isLocked={(info.showPaymentBar &&
                !loadingDebits &&
                debits.length > 0 &&
                !showPresentation)?true:false}
              classDescription={currentClass.description}
              biblioReferences={currentClass.bio}
              materials={currentClass.materiais}
              topics={currentClass.editais}
              key={currentClass.number}
              size={size}
            />
          </MainWrapper>
        </MainContainer>
      )}
      <LoadingModal responsive={true} />
    </ResponsiveContainer>
  );
}

export default ResponsiveCourse;
