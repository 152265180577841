import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import FiberManualRecord from "material-ui-icons/FiberManualRecord";
import { ArrowRight } from "@material-ui/icons";
import Lock from "material-ui-icons/LockOutline";
import { Tooltip } from "@material-ui/core";

import ClassItem from "./ClassItem";

import {
  setShownedClass,
  setShowPresentation,
  classSelectorModalAction,
  moduleFetchSuccess,
  updateModulesWatched,
} from "../Cursos/actions";
import { API as api } from "../../services/servicesAPI";

const ModuleItemWrapper = styled.div`
  width: ${(props) => (props.classSelector ? "100%" : "305px")};
  height: fit-content;
  border-bottom: 1px solid rgb(232, 232, 232);
  border-right: 1px solid rgb(232, 232, 232);
  border-left: 1px solid rgb(232, 232, 232);
  border-top: ${(props) => props.first && "1px solid rgb(232, 232, 232)"};
`;

const ModuleItemContainer = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) =>
    props.expanded ? "rgb(239, 239, 239)" : "rgb(255, 255, 255)"};
  border-radius: 0px;
  height: ${(props) => (props.expanded ? "fit-content" : "48px")};
  width: 100%;
  padding: ${(props) =>
    props.expanded
      ? props.classSelector
        ? "16px 16px 16px 8px"
        : "16px"
      : props.classSelector
      ? "0px 16px 0px 8px"
      : "0px 16px"};
  cursor: pointer;
  &:hover {
    background: rgb(239, 239, 239);
  }
`;

const ModuleItemNumber = styled.span`
  color: rgba(0, 0, 0, 0.75);
  flex: 1;
  text-transform: uppercase;
  font-family: "Proxima Nova";
  font-size: 13px;
  font-weight: 600;
  padding-left: ${(props) => (props.expanded ? "0px" : "16px")};
  line-height: 16px;
`;

const ProgressCounter = styled.div`
  border-radius: 32px;
  font-size: 11px;
  line-height: 18px;
  padding: 0px 6px;
  font-family: Inter;
  color: rgba(0, 0, 0, 0.35);
  margin-left: 4px;

  ${({ concluded }) => concluded && css`
    background: #37CD9B;
    color: #FFFFFF;
  `}
`;

const ModuleItemName = styled.span`
  font-family: "Proxima Nova";
  font-size: 15px;
  color: rgba(0, 0, 0, 0.75);
  line-height: 20px;
`;

const ModuleExpandedInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 16px;
`;

const PresentationItem = styled.div`
  width: ${(props) => (props.classSelector ? "100%" : "305px")};
  height: 48px;
  background: ${(props) =>
    props.active ? "rgb(239, 239, 239)" : "rgb(255,255,255)"};
  display: flex;
  align-items: center;
  border: 1px solid rgb(232, 232, 232);
  border-bottom: none;
  padding-left: ${(props) => (props.classSelector ? "8px" : "16px")};
  cursor: pointer;
  &:hover {
    background: rgb(239, 239, 239);
  }
`;

function ModuleItem({ classSelector = false }) {
  const dispatch = useDispatch();

  const shownedID = useSelector(
    (state) => state.courseGroup.shownedClass.moduleID
  );

  const shownedNumber = useSelector(
    (state) => state.courseGroup.shownedClass.moduleNumber
  );

  const classID = useSelector(
    (state) => state.courseGroup.shownedClass.classID
  );

  const courseID = useSelector(
    (state) => state.courseGroup.currentCourse.data.id
  );

  const modulos = [
    ...useSelector((state) => state.courseGroup.modules.data),
  ].sort((moduleA, moduleB) => moduleA.number - moduleB.number);

  modulos.forEach((modulo) =>
    modulo.aulas.sort((aulaA, aulaB) => aulaA.number - aulaB.number)
  );

  const showPresentation = useSelector(
    (state) => state.courseGroup.currentCourse.showPresentation
  );

  const userPayments = useSelector(
    (state) => state.paymentGroup.userPayments.data
  );

  function setVisibility(id) {
    if (id === shownedID) {
      dispatch(
        setShownedClass({ moduleID: "", classID, moduleNumber: shownedNumber })
      );
    } else {
      dispatch(
        setShownedClass({ moduleID: id, classID, moduleNumber: shownedNumber })
      );
    }
  }

  function isLocked(moduleID, aulas) {
    if (
      userPayments.modulos.find((modulo) => modulo.moduleID === moduleID) !==
        undefined ||
      userPayments.cursos.find((curso) => curso.courseID === courseID) !==
        undefined
    ) {
      return false;
    } else {
      const aula_0 = aulas.find((aula) => aula.number === 0);
      if (aula_0 !== undefined) {
        return (
          userPayments.matriculas.find(
            (matricula) => matricula.aula === aula_0.id
          ) === undefined
        );
      } else {
        return true;
      }
    }
  }

  function showCoursePresentation() {
    dispatch(classSelectorModalAction({ open: false }));
    !classSelector &&
      dispatch(
        setShownedClass({ moduleID: "", classID: "", moduleNumber: "" })
      );
    dispatch(setShowPresentation(true));
  }

  useEffect(() => {
    async function updateWatchStatus() {
      try {
        const { data } = await api.get(`cursos/cursos/${courseID}/aulas-concluidas/`, { headers: { Authorization: localStorage.getItem("token") } });

        dispatch(updateModulesWatched({ payload: data }));
      } catch(err) {
        console.error("Failed to retrieve information from attended classes.", err);
      }
    }

    updateWatchStatus();
  }, []);

  return (
    <div style={{ marginTop: classSelector ? "56px" : "0px" }}>
      <PresentationItem
        classSelector={classSelector}
        active={showPresentation}
        onClick={() => showCoursePresentation()}
      >
        <FiberManualRecord
          style={{
            color: "rgba(0, 0, 0, 0.4)",
            height: "24px",
            width: "24px",
            padding: "6px",
          }}
        />
        <ModuleItemNumber>Apresentação</ModuleItemNumber>
      </PresentationItem>
      {modulos.map((modulo, index) => (
        <ModuleItemWrapper
          classSelector={classSelector}
          key={modulo.id}
          first={index === 0}
        >
          <ModuleItemContainer
            classSelector={classSelector}
            expanded={shownedID === modulo.id}
            onClick={() => setVisibility(modulo.id)}
          >
            <ArrowRight
              style={{
                color: "rgba(0, 0, 0, 0.4)",
                height: "24px",
                width: "24px",
                transform:
                  shownedID === modulo.id ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
            {!(shownedID === modulo.id) ? (
              <ModuleItemNumber>MÓDULO {modulo.number}</ModuleItemNumber>
            ) : (
              <ModuleExpandedInfo>
                <ModuleItemNumber expanded={shownedID === modulo.id}>
                  MÓDULO {modulo.number}
                </ModuleItemNumber>
                <ModuleItemName>{modulo.name}</ModuleItemName>
              </ModuleExpandedInfo>
            )}

            {isLocked(modulo.id, modulo.aulas) ? (
              <Tooltip title="Matricule-se para ter acesso a esse módulo">
                <Lock
                  style={{
                    color: "rgba(0, 0, 0, 0.4)",
                    height: "18px",
                    width: "18px",
                    marginLeft: "16px",
                  }}
                />
              </Tooltip>
            ) : (
              <ProgressCounter concluded={(modulo?.courseProgress >= modulo.aulas.filter(x => (x.number > 0)).length)}>
                {modulo?.courseProgress || 0}/{modulo.aulas.filter(x => (x.number > 0)).length}
              </ProgressCounter>
            )}
          </ModuleItemContainer>
          {shownedID === modulo.id &&
            modulo.aulas.map((classItem, index) => {
              let firstItem = false;
              let lastItem = false;

              //if (index === 0) firstItem = true;
              if (classItem.number === 1) firstItem = true;
              if (index === modulo.aulas.length - 1) lastItem = true;

              //pra voltar ao orginal, retire esse if
              if (classItem.number !== 0) {
                return (
                  <ClassItem
                    classSelector={classSelector}
                    firstItem={firstItem}
                    lastItem={lastItem}
                    key={classItem.id}
                    id={classItem.id}
                    name={classItem.name}
                    number={classItem.number}
                    moduleID={modulo.id}
                    moduleNumber={modulo.number}
                    watched={classItem.watched}
                  />
                );
              }
            })}
        </ModuleItemWrapper>
      ))}
    </div>
  );
}

export default ModuleItem;
