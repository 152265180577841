import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const MarketButtonContainer = styled.a`
  padding: 0px;
  border: none;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  background: #fff;
  border-radius: 6px;
  height:calc( 8vw * 0.75);
  width: calc(26vw * 0.75);
`;
const MarketButtonTextGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-right:calc( 2vw * 0.75);
`;

const MarketButtonText = styled.p`
  margin: 0px;
  color: #000;
  font-family: "Helvetica", sans-serif;
  text-align: left;
  font-size: calc(1.8vw * 0.75);
  height: calc(2.2vw * 0.75);
  line-height: calc(2.2vw * 0.75);
  letter-spacing: 0.72px;
  width: fit-content;
`;

const MarketButtonStoreText = styled.p`
  margin: 0px;
  color: #000;
  font-family: "Helvetica", sans-serif;
  font-size: calc(2.6vw * 0.75);
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  width: fit-content;
`;

export default function MarketButton({ store }) {
  return (
    <div>
      {store === "google" ? (
        <MarketButtonContainer href="https://play.google.com/store/apps/details?id=chat.clipping.reactnative" target="blank">
          <FontAwesomeIcon
            style={{
              color: "#000",
              width: "calc(4.8vw * 0.75)",
              height: "calc(4.8vw * 0.75)",
              alignSelf: "center",
              marginLeft: "calc(2vw * 0.75)",
            }}
            icon={["fab", "google-play"]}
          />
          <MarketButtonTextGroup>
            <MarketButtonText>Download on</MarketButtonText>
            <MarketButtonStoreText>Google Play</MarketButtonStoreText>
          </MarketButtonTextGroup>
        </MarketButtonContainer>
      ) : (
        <MarketButtonContainer href="https://apps.apple.com/us/app/clipping-chat-cacd/id1514423639" target="blank">
          <FontAwesomeIcon
            style={{
              color: "#000",
              width: "calc(4.8vw * 0.75)",
              height: "calc(4.8vw * 0.75)",
              alignSelf: "center",
              marginLeft: "calc(2vw * 0.75)",
            }}
            icon={["fab", "apple"]}
          />
          <MarketButtonTextGroup>
            <MarketButtonText>Download on the</MarketButtonText>
            <MarketButtonStoreText>App Store</MarketButtonStoreText>
          </MarketButtonTextGroup>
        </MarketButtonContainer>
    )}
    </div>
  );
}
