import React, { useState } from 'react';
import styled from 'styled-components';

import ClassTabsNoContent from './ClassTabsNoContent';

const ClassExercicesContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ClassExercicesList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

const ClassExercicesListItem = styled.a`
    text-decoration: none;
`;


function ClassExercices({ exercices = [] }) {
    if (exercices.length === 0) {
        return <ClassExercicesContainer>
            <ClassTabsNoContent />
        </ClassExercicesContainer>
    }

    return <ClassExercicesContainer>
        <ClassExercicesList>
            {exercices.map(exercice =>
                <li key={exercice.id}>
                    <ClassExercicesListItem href={exercice.url}>
                        {exercice.name}
                    </ClassExercicesListItem>
                </li>
            )}
        </ClassExercicesList>
    </ClassExercicesContainer>
}

export default ClassExercices;