import React from "react";
import styled from "styled-components";

import logo from "../../assets/img/clippingchat.png";
import MarketButton from "./MarketButton";

const ChatBannerContainer = styled.div`
  display: flex;
  width: 665px;
  height: 142px;
  background: linear-gradient(-90deg, rgb(242, 55, 0) 0%, rgb(255, 85, 0) 100%);
  border-radius: 8px;
  box-shadow: 0px 11px 32px 0px rgba(0, 0, 0, 0.1);
`;

const ChatBannerImageContainer = styled.div`
  flex: 1.8;
  display: flex;
  justify-content: center;
  align-content: center;
`;

const ChatBannerImage = styled.img`
  align-self: center;
  width: 48px;
  height: 48px;
  object-fit: contain;
  object-position: center;
`;

const ChatBannerTextGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 5.7;
`;
const ChatBannerText = styled.p`
  margin: 0px;
  font-family: "Proxima Nova", sans-serif;
  font-size: 25px;
  font-weight: bold;
  height: 28px;
  line-height: 28px;
  width: fit-content;
  color: #fff;
  margin-bottom: 2px;
`;

const ChatBannerTextInfo = styled.p`
  margin: 0px;
  width: fit-content;
  line-break: normal;
  color: #fff;
  font-size: 15px;
  font-family: "Proxima Nova", sans-serif;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 20px;
  white-space: pre-line;
`;

const ChatBannerStoreGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  /* margin-right: 32px; */
  flex: 2.5;
`;

export default function ChatBanner() {
  return (
    <ChatBannerContainer>
      <ChatBannerImageContainer>
        <ChatBannerImage src={logo} />
      </ChatBannerImageContainer>
      <ChatBannerTextGroup>
        <ChatBannerText>Baixe o app do clipping.chat</ChatBannerText>
        <ChatBannerTextInfo>
          {
            "Para participar das discussões e fazer parte\n da comunidade de Cacdistas."
          }
        </ChatBannerTextInfo>
      </ChatBannerTextGroup>
      <ChatBannerStoreGroup>
        <MarketButton store={"google"} />
        <MarketButton store={"apple"} />
      </ChatBannerStoreGroup>
    </ChatBannerContainer>
  );
}
