import { makeStyles } from '@material-ui/core';

const Styles = () =>
    makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            width: '100%',
        },
    }));

export default Styles;
