import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { getText } from "../../utils/textHandle";

import ClassTabsNoContent from "./ClassTabsNoContent";

import { ErrorOutline } from "@material-ui/icons";

const ClassContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ClassDescription = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 15px;
  line-height: 20px;
  flex-direction: column;

  & > p {
    margin: 10px 0px;
  }
`;

const ClassMaterialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const ClassMaterialsTitle = styled.span`
  color: rgba(0, 0, 0, 0.7);
  font-family: "Proxima Nova";
  font-size: 20px;
  font-weight: bold;
`;

const ClassMaterialsContent = styled.a`
  margin-top: 8px;
  color: rgb(25, 115, 232);
  font-family: "Proxima Nova";
  font-size: 15px;
  line-height: 20px;
  font-weight: normal;
  text-decoration: none;
  cursor: pointer;
`;

const ClassDisabledMaterialsContent = styled.span`
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.35);
  font-family: "Proxima Nova";
  font-size: 15px;
  line-height: 20px;
  font-weight: normal;
`;

const ClassDisabledAlertBox = styled.div`
  width: fit-content;
  background: rgb(255, 236, 194);
  border-radius: 4px;
  margin-top: 10px;
  min-height: 34px;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ClassDisabledText = styled.span`
  width: fit-content;
  font-family: "Proxima Nova";
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 13px;
  margin-left: 10px;
  text-transform: uppercase;
  color: rgb(184, 130, 10);
`;

const ClassTopicsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const ClassTopicsTitle = styled.span`
  color: rgba(0, 0, 0, 0.7);
  font-family: "Proxima Nova";
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ClassTopicsText = styled.span`
  width: fit-content;
  font-family: "Proxima Nova";
  font-size: 15px;
  font-weight: 500;
  height: fit-content;
  letter-spacing: 0px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.75);
`;

const ClassBibliographicsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const ClassBibliographicsTitle = styled.span`
  color: rgba(0, 0, 0, 0.7);
  font-family: "Proxima Nova";
  font-size: 20px;
  font-weight: bold;
`;

const ClassBibliographicsContent = styled.div`
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
  margin-top: 16px;
  & > div {
    margin-bottom: 20px;
  }
  & > p {
    margin: 0px;
  }
`;

function ClassContent({
  description = "",
  materials = [],
  topics = [],
  bibliographics = "",
}) {
  //@Tofix
  const showDescription =
    description === null ? false : description.length > 0 ? true : false;
  const showMaterials =
    materials === null ? false : materials.length > 0 ? true : false;
  const showTopics = topics === null ? false : topics.length > 0 ? true : false;
  const showBibliographics =
    bibliographics === null ? false : bibliographics.length > 0 ? true : false;

  const isEnrolled = useSelector(
    (state) => state.courseGroup.classInfo.data.enrolled
  );

  useEffect(() => {
    const links = document.getElementsByTagName("a");
    for (let i = 0; i < links.length; i++) {
      links[i].setAttribute("target", "_blank");
    }
  }, []);

  if (
    !showDescription &&
    !showMaterials &&
    !showTopics &&
    !showBibliographics
  ) {
    return (
      <ClassContentContainer noContent={true}>
        <ClassTabsNoContent />
      </ClassContentContainer>
    );
  }

  return (
    <ClassContentContainer noContent={false}>
      {showDescription && (
        <ClassDescription
          dangerouslySetInnerHTML={{ __html: getText(description) }}
          style={{ color: "rgba(0, 0, 0, 0.75)" }}
        ></ClassDescription>
      )}
      {showMaterials &&
        (!isEnrolled ? (
          <ClassMaterialsContainer>
            <ClassMaterialsTitle>Materiais</ClassMaterialsTitle>
            {materials.map((material, index) => (
              <ClassDisabledMaterialsContent key={index}>
                {material.name}
              </ClassDisabledMaterialsContent>
            ))}
            <ClassDisabledAlertBox>
              <ErrorOutline
                style={{
                  width: "18px",
                  height: "18px",
                  color: "rgb(184, 130, 10)",
                }}
              />
              <ClassDisabledText>
                Matricule-se para ter acesso aos materiais.
              </ClassDisabledText>
            </ClassDisabledAlertBox>
          </ClassMaterialsContainer>
        ) : (
          <ClassMaterialsContainer>
            <ClassMaterialsTitle>Materiais</ClassMaterialsTitle>
            {materials.map((material, index) => (
              <ClassMaterialsContent key={index} href={material.url} target="_blank">
                {material.name}
              </ClassMaterialsContent>
            ))}
          </ClassMaterialsContainer>
        ))}
      {showTopics && (
        <ClassTopicsContainer>
          <ClassTopicsTitle>Tópicos do edital</ClassTopicsTitle>
          {topics.map((topic, index) => (
            <ClassTopicsText key={index}>
              {topic.orderName} - {topic.nome}
            </ClassTopicsText>
          ))}
        </ClassTopicsContainer>
      )}
      {showBibliographics && (
        <ClassBibliographicsContainer>
          <ClassBibliographicsTitle>
            Referências Bibliográficas
          </ClassBibliographicsTitle>
          <ClassBibliographicsContent
            dangerouslySetInnerHTML={{ __html: getText(bibliographics) }}
          ></ClassBibliographicsContent>
        </ClassBibliographicsContainer>
      )}
    </ClassContentContainer>
  );
}

export default ClassContent;
