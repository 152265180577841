import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { BsCheckCircle } from "react-icons/bs";

import { getText } from "../../utils/textHandle";

import ClassTabs from "./ClassTabs";
import ModuleItem from "./ModuleItem";
import CourseHeader from "../../components/CourseHeader/";
import ScrolledCourseHeader from "../../components/CourseHeader/scrolled";
import SidebarCollapseButton from "../../components/SidebarCollapseButton";
import ClassDate from "../../components/ClassDate";
import Video from "./Video";
import PaymentBar from "./PaymentBar";
import DefaultLoading from "../../components/Loading";
import LoadingModal from "../Payments/Modais/LoadingModal.js";
import { API as api } from "../../services/servicesAPI";
import { removeModuleWatched, updateModulesWatched } from "../Cursos/actions";


const MainContainer = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 120vh;
  display: flex;
  justify-content: ${(props) => (props.show ? "flex-start" : "center")};
  padding-bottom: 130px;
  padding-left: ${(props) =>
    props.show ? (props.scrolled ? "305px" : "0px") : "0px"};

  @media (max-width: 1060px) {
    #container {
      padding-left: ${(props) =>
        props.show ? "calc((100vw - 970px) / 2)" : "0px"};
    }
  }
  @media (min-width: 1060px) and (max-width: 1366px) {
    #container {
      padding-left: ${(props) => (props.show ? "45px" : "0px")};
    }
  }
  @media (min-width: 1366px) {
    #container {
      padding-left: ${(props) =>
        props.show ? "calc((100vw - 665px) / 2 - 305px)" : "0px"};
    }
  }
`;

const ClassLoadingContainer = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 50%;
  width: fit-content;
  height: fit-content;
`;

const SidebarLoadingContainer = styled.div`
  top: 50%;
  position: fixed;
  width: fit-content;
  height: fit-content;
`;

const SidebarContainer = styled.div`
  z-index: 2;
  width: 305px;
  display: flex;
  justify-content: center;
  border-right: 1px solid rgb(232, 232, 232);
  height: ${(props) => props.scrolled && "100vh"};
  overflow-y: ${(props) => props.scrolled && "scroll"};
  position: ${(props) => (props.scrolled ? "fixed" : "relative")};
  top: ${(props) => props.scrolled && "0px"};
  left: 0px;
  scrollbar-width: thin;
  scrollbar-color: #fff #ddd;

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: #ccc;
    }
  }
  &::-webkit-scrollbar {
    width: 2px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #fff;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

const ContainerGroup = styled.div`
  position: relative;
  width: fit-content;
  z-index: 3;
  padding-top: 24px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
`;

const ContainerWrapper = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  align-self: center;
  flex-direction: column;
  justify-content: space-around;

  & > * {
    margin-bottom: 24px;
  }
`;

const ClassInfoGroup = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

const ClassTitle = styled.span`
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 25px;
  font-weight: 800;
  max-width: 665px;
  width: fit-content;
  letter-spacing: -0.8px;
  line-height: 28px;
`;

const CoursePresentation = styled.div`
  width: 665px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const PresentationTitle = styled.span`
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 25px;
  font-weight: 800;
  height: 28px;
  letter-spacing: -0.8px;
  line-height: 28px;
  width: fit-content;
  margin-top: 16px;
  margin-bottom: 40px;
`;

const PresentationContent = styled.div`
  color: rgba(0, 0, 0, 0.75);
  font-family: "Proxima Nova";
  font-size: 15px;
  font-weight: normal;
  height: fit-content;
  letter-spacing: 0px;
  line-height: 20px;
  width: 100%;
  padding-bottom: 60px;
`;

const CheckConcludedButton = styled.button`
  display: flex;
  align-items: center;
  border: 1px solid #E8E8E8;
  border-radius: 8px;
  background: #FFFFFF;
  width: fit-content;
  min-width: 214px;
  font-family: Inter;
  color: #808080;
  font-size: 14px;
  line-height: 20px;
  padding: 16px;
  transition: 125ms;

  :before {
    content: " ";
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    height: 16px;
    aspect-ratio: 1;
    margin-right: 8px;
  }

  :hover {
    filter: brightness(0.95);
  }

  :active {
    transition: 0ms;
    filter: brightness(0.96);
  }

  ${({ watched }) => watched && css`
    color: #FFFFFF;
    background: #37CD9B;
    border: none;

    >svg {
      height: 16px;
      width: 16px;
      margin-right: 8px;
    }

    ::before {
      display: none;
    }
  `}
`;

function DesktopCourse({ scrolled, scrollTop, setScrolled, debits }) {

  const [show, setShow] = useState(true);
 

  const dispatch = useDispatch();

  const { data: currentCourse, showPresentation } = useSelector(
    (state) => state.courseGroup.currentCourse
  );

  const {
    currentCourse: { data: { id: curso } },
    currentModule: { data: { id: modulo } },
    currentClass: { data: { id: aula } }
  } = useSelector(state => state.courseGroup);

  const currentClass = useSelector(
    (state) => state.courseGroup.currentClass.data
  );

  const loadingClass = useSelector(
    (state) => state.courseGroup.classInfo.loading
  );

  const loadingDebits = useSelector(
    (state) => state.paymentGroup.debits.loading
  );

  const loadingModules = useSelector(
    (state) => state.courseGroup.modules.loading
  );

  const modules = useSelector(
    (state) => state.courseGroup.modules.data
  );

  const courseID = useSelector(
    (state) => state.courseGroup.currentCourse.data.id
  );

  const info = useSelector((state) => state.courseGroup.classInfo.data);

  const currentDebit = useSelector((state) => state.paymentGroup.currentDebit);

  const userPayments = useSelector(
    (state) => state.paymentGroup.userPayments.data
  );

  function wasWatched() {
    const watchedModule = modules.filter(x => x.id === modulo)[0]?.aulas;

    if(!watchedModule || !Array.isArray(watchedModule))
      return;

    const watchedClassroom = watchedModule.filter(y => y.id === aula)[0];

    if(!watchedClassroom)
      return;

    return watchedClassroom?.watched;
  }

  function isLocked(moduleID, aulas = []) {
    if (
      userPayments.modulos.find((modulo) => modulo.moduleID === moduleID) !==
        undefined ||
      userPayments.cursos.find((curso) => curso.courseID === courseID) !==
        undefined
    ) {
      return false;
    } else {
      const aula_0 = aulas.find((aula) => aula.number === 0);
      if (aula_0 !== undefined) {
        return (
          userPayments.matriculas.find(
            (matricula) => matricula.aula === aula_0.id
          ) === undefined
        );
      } else {
       
        return true;
      }
    }
  }

  async function handleCheckConcluded() {
    const watched = wasWatched();

    if(watched) {
      try {
        await api.delete(`cursos/cursos/${curso}/aulas-concluidas/${watched.id}`, { headers: { Authorization: localStorage.getItem("token") } });

        return dispatch(removeModuleWatched({ payload: { modulo, curso, aula } }));
      } catch(err) {
        console.error("Failed to uncheck as complete.", err);
      }

      return console.log({ watched });
    }

    try {
      const { data } = await api.post(`cursos/cursos/${curso}/aulas-concluidas/`, { curso, modulo, aula }, { headers: { Authorization: localStorage.getItem("token") } });
      
      dispatch(updateModulesWatched({ payload: [data] }));
    } catch(err) {
      console.error("Failed to mark as complete.", err);
    }
  }
  useEffect(() => {
      if(!curso && !modulo && !aula){
        return window.location.href ='/cursos';
      }
  }, []);

  return (
    <div>
      <CourseHeader
        backgroundUrl={currentCourse.image}
        profileUrl={
          "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/512px-Circle-icons-profile.svg.png"
        }
      />
      <MainContainer scrolled={scrolled} show={show}>
        {show && (
          <SidebarContainer scrolled={scrolled} show={show}>
            {loadingModules ? (
              <SidebarLoadingContainer>
                <DefaultLoading sidebar={true} />
              </SidebarLoadingContainer>
            ) : (
              <ModuleItem />
            )}
          </SidebarContainer>
        )}
        <ContainerGroup id="container">
          {info.showPaymentBar && !loadingDebits && debits.length > 0 && (
            <PaymentBar
              imageUrl={debits[0].teacher}
              moduleNumber={debits[0].modulo.number}
              courseName={debits[0].curso.name}
              courseYear={debits[0].curso.ano}
              classNumber={debits[0].aula.number}
              className={debits[0].aula.name}
              category={debits[0].curso.categoria}
              pacoteID={currentDebit.id}
            />
          )}
          {loadingClass || loadingModules ? (
            <ClassLoadingContainer>
              <DefaultLoading sidebar={true} />
            </ClassLoadingContainer>
          ) : showPresentation ? (
            <CoursePresentation>
              <PresentationTitle>Apresentação do curso 🖐</PresentationTitle>
              <PresentationContent
                dangerouslySetInnerHTML={{
                  __html: getText(currentCourse.description),
                }}
              ></PresentationContent>
            </CoursePresentation>
          ) : (
            <ContainerWrapper>
              {/* <SidebarCollapseButton show={show} setShow={setShow} /> */}
              <ClassInfoGroup>
                <ClassTitle>{currentClass.name}</ClassTitle>
                {info.classTempo && info.type !== 'notEnrolled' && (
                  <ClassDate
                    date={info.availableDate}
                    time={info.time}
                    classTempo={info.classTempo}
                  />
                )}
              </ClassInfoGroup>

              <Video debits={debits.length > 0} />

              {!isLocked(modulo, modules.filter(x => x.id === modulo)[0]?.aulas) && (
                <CheckConcludedButton watched={wasWatched()} onClick={handleCheckConcluded}>
                  {wasWatched() && (
                    <BsCheckCircle />
                  )}

                  Marcar como concluída
                </CheckConcludedButton>
              )}

              <ClassTabs
                classDescription={currentClass.description}
                courseLocked={isLocked(modulo, modules.filter(x => x.id === modulo)[0]?.aulas)?true:false}
                biblioReferences={currentClass.bio}
                materials={currentClass.materiais}
                topics={currentClass.topicos}
                key={currentClass.number}
                cursoId={courseID}
                moduloId={modulo}
                classId={currentClass.id}
                forumGroup={currentCourse.forum_group}
                forumRedirect={currentCourse.forum_redirect}
                classSummary={currentClass.class_summary}
              />
            </ContainerWrapper>
          )}
        </ContainerGroup>
      </MainContainer>
      <LoadingModal />
    </div>
  );
}

export default DesktopCourse;
