import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";


import ChatBanner from "../../components/ChatBanner";
import ClassExercices from "./ClassExercices";
import ClassContent from "./ClassContent";
import { API } from "../../services/servicesAPI";
import { setAuthData } from "../../actions/clippingChatAction";
import ChatRoomView from "../../components/ChatEmbed";

const ClassTabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 665px;
`;

const ClassTabsContent = styled.div`
  display: flex;
  width: 665px;
  padding-top: 32px;
`;

const TabsContainer = styled.div`
  display: flex;
  box-shadow: inset 0px -1px 0px 0px rgb(232, 232, 232);
  width: 100%;
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
  cursor: pointer;
  border: none;
  user-select: none;
  border-bottom: ${(props) =>
    props.active ? "2px solid rgb(252, 121, 84)" : "2px solid transparent"};
  
  span {
      color: ${(props) =>
        props.active ? "rgb(252, 121, 84)" : "rgba(0, 0, 0, 0.5);"};
  }
  
  &:hover {
    span {
      color: rgb(252, 121, 84);
    }
  }
`;

const TabName = styled.span`
  height: 16px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  font-family: "Proxima Nova";
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
`;

const CLASS_CONTENT_TAB = "class_content";
const CLASS_SUMMARY_TAB = "resumo";
const CLASS_BIBLIOGRAPHY_TAB = "plano_de_estudos";
const CHAT_TAB = "forum";

function ClassTabs({ classDescription, materials, topics, biblioReferences, courseLocked, cursoId, moduloId, classId, forumGroup, forumRedirect, classSummary }) {
  const [activeTab, setActiveTab] = useState(CLASS_BIBLIOGRAPHY_TAB);
  const dispatch = useDispatch();
  const { userId, authToken } = useSelector(
    (state) => state.clippingChat
  );

  async function inviteToForumGroup(){
    API.post('cursos/discourse/add-to-group/', {
      curso_id: cursoId,
      modulo_id: moduloId,
    }, {
      headers: {
        Authorization: localStorage.getItem("token"),
      }
    }).then(data => {
      alert("Acesso à discussão concedido")
      window.open('https://forum.clippingcacd.com.br/' + forumRedirect, '_blank')
    })
    .catch(error => {
      alert(error.response.data)
      window.open('https://forum.clippingcacd.com.br/' + forumRedirect, '_blank')
    })
    
  }

  return (
    <ClassTabsContainer>
      <TabsContainer>
      <Tab
          onClick={() => {
            setActiveTab(CLASS_BIBLIOGRAPHY_TAB)
          }}
          active={activeTab === CLASS_BIBLIOGRAPHY_TAB}
        >
          <TabName>Plano de Estudos</TabName>
        </Tab>
        <Tab
          onClick={() => {
            setActiveTab(CLASS_CONTENT_TAB)
          }}
          active={activeTab === CLASS_CONTENT_TAB}
        >
          <TabName>Referências</TabName>
        </Tab>
        <Tab
          onClick={() => {
            setActiveTab(CLASS_SUMMARY_TAB)
          }}
          active={activeTab === CLASS_SUMMARY_TAB}
        >
          <TabName>Tópicos abordados</TabName>
        </Tab>

        {forumGroup && (
          <Tab
            onClick={() => {
              setActiveTab(CHAT_TAB)
            }}
            active={activeTab === CHAT_TAB}
          >
            <TabName>Fórum</TabName>
          </Tab>
          )
        }
      </TabsContainer>
      <ClassTabsContent>
        {activeTab === CLASS_CONTENT_TAB && (
          <ClassContent
            bibliographics={biblioReferences}
          />
        )}
        {activeTab === CLASS_SUMMARY_TAB && 
          <div
            dangerouslySetInnerHTML={{ __html: classSummary }}
            style={{ color: "rgba(0, 0, 0, 0.75)" }}
          >
          </div>
        }
        
        {activeTab === CLASS_BIBLIOGRAPHY_TAB && <p>
          <ClassContent
            description={classDescription}
            materials={materials}
            topics={topics}
          />
        </p>}

        {activeTab === CHAT_TAB && (
          <div style={{height: 400 }}>
            <p>
              👉 Primeiro acesso? Siga as orientações abaixo:
            </p>
            <ol>
              <li>Faça login usando seu usuário e senha <a style={ {textDecoration: 'underline', cursor: 'pointer'}} href="https://forum.clippingcacd.com.br" target="_blank">neste link.</a></li>
              <li><span style={ {textDecoration: 'underline', cursor: 'pointer'}} onClick={inviteToForumGroup}>Clique aqui para ser convidado para a sala da disciplina.</span></li>
            </ol>
            <p>
              👉 Já faz parte do fórum?
            </p>
            <ol>
              {/* <li><span style={ {textDecoration: 'underline', cursor: 'pointer'}} onClick={inviteToForumGroup}>Clique aqui para ser convidado para a sala da disciplina.</span></li> */}
              <li><a href={`https://forum.clippingcacd.com.br/${forumRedirect}`} target="_blank">Clique aqui para acessar e participar das discussões.</a></li>
            </ol>
          </div>
        )}
      </ClassTabsContent>
    </ClassTabsContainer>
  );
}

export default ClassTabs;


// :<ChatRoomView userId={userId} authToken={authToken} />