import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import api from "./newApi";

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

import {
  setPendingPayment,
  initPendingPayment,
  clearCurrentPayment,
  setBoletoUrl,
  userPaymentsFetchRequest,
} from "../containers/Payments/actions";

export default function PaymentSocket() {
  const dispatch = useDispatch();
  const { old_id: oldID, id: userID } = useSelector(
    (state) => state.userData.data
  );

  async function fetchUserPayments() {
    try {
      const headers = { Authorization: localStorage.getItem("token") };

      let initPacotes = [];
      const userSearch = {
        index: "student",
        query: {
          match: {
            id: userID,
          },
        },
        size: 1,
      };
      const { data: response } = await api.post("/elastic/", userSearch, {
        headers,
      });
      const userPayments = [...response.results[0].payment_user];

      if (userPayments.length) {
        for (const payment of userPayments) {
          if (
            payment.status !== "paid" &&
            payment.status !== "refused" &&
            payment.status !== "unpaid" &&
            payment.status !== "canceled"
          ) {
            let body = {
              index: "payments",
              query: {
                bool: {
                  must: [
                    // {
                    //   match: {
                    //     "perfil.id": profile,
                    //   },
                    // },
                    {
                      match: {
                        payment_id: payment.payment_id,
                      },
                    },
                  ],
                },
              },
              size: 1000,
            };

            const { data: paymentData } = await api.post("/elastic/", body, {
              headers,
            });

            if (paymentData.results.length) {
              const paymentPacotes = [...paymentData.results[0].pacote];
              const matriculas = [];

              paymentPacotes.forEach((pacote) => {
                pacote.aulas.forEach((aula) => {
                  if (aula.number === 0) {
                    matriculas.push(aula.id);
                    pacote.matricula = true;
                  }
                });
              });

              if (matriculas.length) {
                for (const aulaID of matriculas) {
                  const moduleSearch = {
                    index: "modulos",
                    query: {
                      nested: {
                        path: "aulas",
                        query: {
                          bool: {
                            must: [
                              {
                                match: {
                                  "aulas.id": aulaID,
                                },
                              },
                            ],
                          },
                        },
                      },
                    },
                  };

                  const { data: response } = await api.post(
                    "/elastic/",
                    moduleSearch,
                    { headers }
                  );

                  paymentPacotes.push(response.results[0].pacotes_modulo[0]);
                }
              }
              const filterPacotes = [
                ...paymentPacotes
                  .filter((pacote) => !pacote.matricula)
                  .map((pacote) => pacote.id),
              ];
              
              const pacotesSet = new Set(filterPacotes);
              const pacotes = [...pacotesSet]

              initPacotes = initPacotes.concat(pacotes);
            }
          }
        }
        dispatch(initPendingPayment({ payload: initPacotes }));
      } else {
        dispatch(initPendingPayment({ payload: "blank" }));
      }
    } catch (error) {
      // console.log(`Error in socket: ${error}`);
    }
  }

  useEffect(() => {
    try {
      const headers = { Authorization: localStorage.getItem("token") };

      fetchUserPayments();

      const socket = new WebSocket(`${SOCKET_URL}/${oldID}/`);

      socket.onmessage = async function(e) {
        const { content } = JSON.parse(e.data);

        if (content.status !== "processing") {
          let body = {
            index: "payments",
            query: {
              bool: {
                must: [
                  // {
                  //   match: {
                  //     "perfil.id": profile,
                  //   },
                  // },
                  {
                    match: {
                      payment_id: content.payment_id,
                    },
                  },
                ],
              },
            },
            size: 1000,
          };

          const { data: paymentData } = await api.post("/elastic/", body, {
            headers,
          });

          const paymentPacotes = [...paymentData.results[0].pacote];
          const matriculas = [];

          paymentPacotes.forEach((pacote) => {
            pacote.aulas.forEach((aula) => {
              if (aula.number === 0) {
                matriculas.push(aula.id);
                pacote.matricula = true;
              }
            });
          });

          if (matriculas.length) {
            for (const aulaID of matriculas) {
              const moduleSearch = {
                index: "modulos",
                query: {
                  nested: {
                    path: "aulas",
                    query: {
                      bool: {
                        must: [
                          {
                            match: {
                              "aulas.id": aulaID,
                            },
                          },
                        ],
                      },
                    },
                  },
                },
              };

              const { data: response } = await api.post(
                "/elastic/",
                moduleSearch,
                { headers }
              );

              paymentPacotes.push(response.results[0].pacotes_modulo[0]);
            }
          }
          content.pacotes = [
            ...paymentPacotes.filter((pacote) => !pacote.matricula),
          ];
          if (content.status === "paid") {
            dispatch(setPendingPayment({ payment: content }));
            dispatch(userPaymentsFetchRequest());
            if (content.boleto_url) {
              dispatch(setBoletoUrl(""));
            }
          } else {
            dispatch(setPendingPayment({ payment: content }));
            if (content.boleto_url) {
              dispatch(setBoletoUrl(content.boleto_url));
            }
          }
        } else {
          dispatch(clearCurrentPayment());
        }
      };
      return () => socket.close();
    } catch (error) {
      // console.log(`Error in socket: ${error}`);
    }

    const interval = setInterval(() => {
      fetchUserPayments();
    }, 15 * 60 * 1000);
    return () => clearInterval(interval);
  }, [userID]);

  return <div />;
}
