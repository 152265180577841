import React, { Component } from 'react';
import { withStyles } from 'material-ui/styles';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Bar, Line } from 'react-chartjs-2';
import Grid from 'material-ui/Grid';
import _ from 'lodash';
import moment from 'moment';
import { loadReport as loadReportActions } from '../../actions/loadReportActions';

import { Chart, LineElement, PointElement, LinearScale } from "chart.js";

Chart.register(LineElement, PointElement, LinearScale);

moment.locale('pt-BR');
const mounthFormat = (date) => moment(date).format('L');

const styles = (theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(height + 10vw)',
      backgroundColor: '#f9f9f9',
    },
  },
  wrapper: {
    flex: 1,
    display: 'flex',
    background: '#f9f9f9',
    overflow: 'auto',
    overflowX: 'hidden',
    height: '100%',

    padding: '6% 7% 10% 7%',
  },
  indicatorsOfUser: {
    display: 'flex',
    alignItems: 'flex-start',
    direction: 'row',

    justify: 'flex-start',
    '& h2': {
      margin: '10px 0 0 0',
      padding: 0,
      fontSize: '1.3em',
    },
    '& p': {
      color: '#F8A947',
      margin: 0,
      padding: 0,
      fontSize: '1.3em',
      fontWeight: '300',
    },
  },
  divisor: {
    width: '100%',
    backgroundColor: '#ddd',
    height: '1px',
    margin: '20px 0 0 0',
  },
  chartContent: {
    height: '350px',
    margin: '20px 0 100px 0',
    padding: '0 10px',
    '& h2': {
      fontWeight: 'normal',
      fontSize: '0.6em',
    },
  },
});


class ReportContent extends Component {
  componentWillMount() {
    if (this.props._id || (this.props._id !== this.props._id)) {
      this.props.loadReport(this.props._id);
    }
  }

  componentDidUpdate(nextProps) {
    const { _id } = nextProps;
    if ((this.props._id !== _id) && Boolean(this.props._id)) {
      this.props.loadReport(this.props._id);
    }
  }

  render() {
    const { classes, dataReport, _id } = this.props;

    const ranking = _.orderBy(dataReport.ranking, ['_id'], ['asc']);
    const history = dataReport.history ? dataReport.history.docs : null;
    const numberOfUsers = _.sumBy(ranking, (o) => o.scoreTotal);
    const lblScores = [];
    const dataBarXAxis = [];

    ranking.forEach((score) => {
      lblScores.push(score._id);
      dataBarXAxis.push(score.scoreTotal);
    });

    const lastScore = _.orderBy(history, ['createdAt'], ['desc']);
    const scoreOfUser = _.find(history, (o) => o.isDeleted === false);

    const dataBar = {
      labels: lblScores,
      datasets: [
        {
          label: ' Quantidade de pessoas',
          backgroundColor: 'rgba(255,99,132,0.2)',
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: dataBarXAxis,
        },
      ],
    };

    const lineScores = [];
    const monthsLineChart = [];

    lastScore.forEach((x) => {
      lineScores.push(x.score);
      monthsLineChart.push(mounthFormat(x.createdAt));
    });

    const dataLine = {
      labels: monthsLineChart.reverse(),
      datasets: [
        {
          label: ' Nota',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#000',
          pointBorderWidth: 10,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: lineScores.reverse(),
        },
      ],
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
            },
          }],
        },
      },
    };

    const position = _.sumBy(ranking, (o) => (parseInt(o._id, 10) > scoreOfUser.score ? o.scoreTotal : 0)) + 1;

    return (
      <div className={classes.container}>
        <div className={classes.wrapper}>
          {history && (
          <Grid container className={classes.indicatorsOfUser}>
            <Grid item xs={12} md={2}>
              <h2>Sua nota</h2>
              <p>{scoreOfUser.score}</p>
            </Grid>
            <Grid item xs={12} md={2}>
              <h2>Participantes</h2>
              <p>{numberOfUsers}</p>
            </Grid>
            <Grid item xs={12} md={2}>
              <h2>Você ficou em</h2>
              <p>
                {position}
                º lugar
              </p>
            </Grid>
            <span className={classes.divisor} />
            <Grid item xs={12} md={6} className={classes.chartContent}>
              <p>Usuários agrupados por nota</p>
              <Bar
                data={dataBar}
                options={{
                  legend: { display: false },
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [{
                      ticks: {
                        beginAtZero: true,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: 'Quantidade de pessoas',
                      },
                    }],
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Nota',
                      },
                    }],
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.chartContent}>
              <p>Evolução das suas notas neste simulado</p>
              <Line
                data={dataLine}
                options={{
                  legend: { display: false },
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [{
                      ticks: {
                        beginAtZero: true,
                      },
                      scaleLabel: {
                        display: true,
                        labelString: 'Nota',
                      },
                    }],
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Data de submissão',
                      },
                    }],
                  },
                }}
              />
            </Grid>
          </Grid>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dataReport: state.dataReport,
});

const mapDispatchToProps = {
  loadReport: (id) => loadReportActions(id),
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ReportContent);
